import { mapHelper } from "@/utils/common.js";
//是否党员
const isPartyStatus = [
  {
    value: 1,
    label: "是",
  },
  {
    value: 2,
    label: "否",
  },
];

const { map: isPartyStatusMap, setOps: isPartyStatusOps } =
  mapHelper.setMap(isPartyStatus);

// 是否干部
const isCadre = [
  {
    value: 1,
    label: "是",
  },
  {
    value: 2,
    label: "否",
  },
];

const { map: isCadreMap, setOps: isCadreOps } = mapHelper.setMap(isCadre);

export { isPartyStatus, isPartyStatusMap, isCadre, isCadreMap };
